import React, { useEffect, useState } from 'react'
import { TableS3H } from './component/Table'
import { columnDefinitionLeasedAssets } from './component/Table/column'
import { FiPlus, FiTrash } from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import axios from 'axios'
import * as types from './reportData.json'
import { FaChevronDown } from 'react-icons/fa'

const ReportLeasedAssets = () => {
  const column = columnDefinitionLeasedAssets
  const navigate = useNavigate()
  const location = useLocation()
  const axiosPrivate = useAxiosPrivate()
  const { bypassModal } = location.state === null ? false : location.state
  const {
    fuel,
    fuelEF,
    vehicleEF,
    vehicleMode,
    transportMode,
    refrigerant,
  } = types

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showContentModal, setShowContentModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [modalMode, setModalMode] = useState(null) // 'add' or 'edit'
  const [editableData, setEditableData] = useState({
    category: '',
    reportBy: '',
    assetCategory: '',
    assetType: '',
    lessor: '',
    industryType: '',
    buildingType: '',
    fuel: '',
    unit: '',
    quantity: 0,
    region: '',
    allocation: 0,
    totalAllocation: 0,
    occupancyRate: 0,
    electricity: 0,
    refrigerant: '',
    leakage: 0,
    processActivity: 0,
    assetEF: 0,
    buildingEF: 0,
    description: '',
  })
  const [selectedRows, setSelectedRows] = useState(new Set())
  const [rowToDelete, setRowToDelete] = useState(null)
  const [categoryToDelete, setCategoryToDelete] = useState(null)

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const fetchData = async () => {
      setIsLoading(true)
      try {
        const upstreamResponse = await axiosPrivate.get(
          'upstreamAssets/read-S3H-records',
          {
            signal: controller.signal,
          },
        )

        const downstreamResponse = await axiosPrivate.get(
          'downstreamAssets/read-S3I-records',
          {
            signal: controller.signal,
          },
        )

        if (isMounted) {
          // console.log(response.data);
          setData([...upstreamResponse.data, ...downstreamResponse.data])
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          // Log for debugging purposes
          // console.log('Request cancelled:', err.message);
        } else {
          console.error('Request failed:', err)
          // Only navigate if the error was not a cancellation
          // Check for a 403 status code specifically
          if (err.response && err.response.status === 403) {
            navigate('/sign-in', { state: { from: location }, replace: true })
          }
        }
      }

      setIsLoading(false)
    }

    fetchData()

    if (bypassModal === true) {
      handleAddNewClick()
    }

    return () => {
      isMounted = false
      controller.abort()
      // console.log('Cleanup: Cancelled any ongoing requests.');
    }
  }, [navigate, location, axiosPrivate])

  const handleAddNewClick = () => {
    setEditableData({
      category: '',
      reportBy: '',
      assetCategory: '',
      assetType: '',
      lessor: '',
      industryType: '',
      buildingType: '',
      fuel: '',
      unit: '',
      quantity: 0,
      region: '',
      allocation: 0,
      totalAllocation: 0,
      occupancyRate: 0,
      electricity: 0,
      refrigerant: '',
      leakage: 0,
      processActivity: 0,
      assetEF: 0,
      buildingEF: 0,
      description: '',
    })
    setModalMode('add')
    setShowContentModal(true)
  }

  const handleDeleteClick = () => {
    if (selectedRows.size > 0) {
      setShowDeleteModal(true)
    } else {
      alert('No rows selected') // Or show a more user-friendly message
    }
  }

  const requestRowDeletion = (rowCategory, rowId) => {
    setCategoryToDelete(rowCategory)
    setRowToDelete(rowId)
    setShowDeleteModal(true)
  }

  const confirmDelete = async () => {
    if (rowToDelete !== null) {
      let isMounted = true
      const controller = new AbortController()

      try {
        const stream = categoryToDelete === 'Upstream' ? 'H' : 'I'
        await axiosPrivate.delete(
          `/${categoryToDelete.toLowerCase()}Assets/delete-S3${stream}-record/${rowToDelete}`,
          {
            headers: { 'Content-Type': 'application/json' },
            signal: controller.signal,
          },
        )

        if (isMounted) {
          // Update the state to reflect the deletion
          setData((prevData) =>
            prevData.filter((row) => row.id !== rowToDelete),
          )
          setRowToDelete(null)
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error('Deletion failed:', err)
          if (err.response) {
            // Redirect on 403 Forbidden or show an error message
            if (err.response.status === 403) {
              navigate('/sign-in', {
                state: { from: location },
                replace: true,
              })
            } else {
              alert(
                `Error: ${err.response.data.message || 'An error occurred.'}`,
              )
            }
          }
        }
      } finally {
        if (isMounted) {
          setShowDeleteModal(false) // Close the modal upon completion or error
        }
      }

      // Cleanup function to prevent state updates if the component unmounts
      return () => {
        isMounted = false
        controller.abort()
      }
    } else {
      // If rowToDelete is null, just close the modal
      setShowDeleteModal(false)
    }
  }

  const deleteSelectedRows = async () => {
    // Check if any rows are selected for deletion
    if (selectedRows.size > 0) {
      let isMounted = true
      const controller = new AbortController()

      try {
        const recordIdsToDelete = Array.from(selectedRows)

        await axiosPrivate.delete('/businessTravel/delete-S3G-records', {
          data: { ids: recordIdsToDelete }, // Sending user IDs in the request body
          headers: { 'Content-Type': 'application/json' },
          signal: controller.signal,
        })

        if (isMounted) {
          // Filter out the deleted users from the current data
          setData((prevData) =>
            prevData.filter((row) => !selectedRows.has(row.id)),
          )
          setSelectedRows(new Set()) // Clear selected rows after successful deletion
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error('Deletion failed:', err)
          if (err.response) {
            // Handle specific HTTP status codes here
            if (err.response.status === 403) {
              navigate('/sign-in', {
                state: { from: location },
                replace: true,
              })
            } else {
              alert(
                `Error: ${err.response.data.message || 'An error occurred.'}`,
              )
            }
          }
        }
      } finally {
        if (isMounted) {
          setShowDeleteModal(false) // Close the modal upon completion or error
        }
      }

      // Cleanup function to prevent state updates if the component unmounts
      return () => {
        isMounted = false
        controller.abort()
      }
    } else {
      // If no rows were selected, just close the modal
      setShowDeleteModal(false)
    }
  }

  // Function to handle edit requests
  const handleEditClick = (rowData) => {
    setEditableData(rowData)
    setModalMode('edit')
    setShowContentModal(true)
  }

  const handleAddNewSubmit = async (event) => {
    event.preventDefault()
    const stream = editableData.category === 'Upstream' ? 'H' : 'I'

    const endpoint =
      modalMode === 'edit'
        ? `/${editableData.category.toLowerCase()}Assets/edit-S3${stream}-record/${
            editableData.id
          }`
        : `/${editableData.category.toLowerCase()}Assets/create-S3${stream}-record`
    const method = modalMode === 'edit' ? 'patch' : 'post'

    // Prepare the data payload
    const payload = {
      scope: 3,
      category: editableData.category,
      reportBy: editableData.reportBy,
      assetCategory: editableData.assetCategory,
      assetType: editableData.assetType,
      lessor: editableData.lessor,
      industryType: editableData.industryType,
      buildingType: editableData.buildingType,
      fuel: editableData.fuel,
      unit: editableData.unit,
      quantity: editableData.quantity,
      region: editableData.region,
      allocation: editableData.allocation,
      totalAllocation: editableData.totalAllocation,
      occupancyRate: editableData.occupancyRate,
      electricity: editableData.electricity,
      refrigerant: editableData.refrigerant,
      leakage: editableData.leakage,
      processActivity: editableData.processActivity,
      assetEF: editableData.assetEF,
      buildingEF: editableData.buildingEF,
      description: editableData.description,
    }

    try {
      const response = await axiosPrivate[method](endpoint, payload, {
        headers: { 'Content-Type': 'application/json' },
      })

      // Update the state based on the modal mode
      if (modalMode === 'edit') {
        setData((currentData) =>
          currentData.map((item) =>
            item.id === editableData.id ? { ...item, ...response.data } : item,
          ),
        )
      } else {
        setData((currentData) => [...currentData, response.data])
      }

      // Reset form and close modal
      setEditableData({
        category: '',
        reportBy: '',
        assetCategory: '',
        assetType: '',
        lessor: '',
        industryType: '',
        buildingType: '',
        fuel: '',
        unit: '',
        quantity: 0,
        region: '',
        allocation: 0,
        totalAllocation: 0,
        occupancyRate: 0,
        electricity: 0,
        refrigerant: '',
        leakage: 0,
        processActivity: 0,
        assetEF: 0,
        buildingEF: 0,
        description: '',
      })
      setShowContentModal(false)
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error('Submission failed:', err)
        const errorMsg = err.response?.data.message || 'An error occurred.'
        if (err.response?.status === 403) {
          navigate('/sign-in', { state: { from: location }, replace: true })
        } else {
          alert(`Error: ${errorMsg}`)
        }
      }
    }
  }

  function allocationLabel() {
    if (editableData.reportBy === 'Lessor-Specific Method') {
      return (
        <p>
          Allocation (m<sup>2</sup> or m<sup>3</sup>)
        </p>
      )
    } else {
      return (
        <p>
          Floor Space (m<sup>2</sup>)
        </p>
      )
    }
  }

  function quantityLabel() {
    const labels = {
      'Fuel Consumption': 'Fuel Consumed',
      'Distance Travelled': 'Distance Transported (km)',
      'Amount Spent': 'Amount Spent (RM)',
      'Site-Specific Method': 'Fuel Consumed',
    }

    if (editableData.reportBy !== 'Average-Data Method') {
      return labels[editableData.reportBy]
    } else {
      return (
        <p>
          Total Volume of Stored Good (m<sup>3</sup>)
        </p>
      )
    }
  }

  if (isLoading) {
    return <div>Loading...</div> // Or any loading spinner
  }

  return (
    <div className="flex flex-col bg-slate-400 dark:bg-stone-800 text-black dark:text-white p-4 md:p-10 w-full overflow-y-auto h-full">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <h2 className="text-xl md:text-2xl font-bold mb-4 md:mb-0 dark:text-white">
          Leased Assets Reporting
        </h2>
        <div className="flex space-x-2">
          <button
            onClick={handleAddNewClick}
            className="flex items-center justify-center bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-800 transition duration-300 w-40"
            aria-label="Add new item"
          >
            <FiPlus className="mr-2" />
            <span className="flex-1 text-center">Add</span>
          </button>
          <button
            onClick={handleDeleteClick}
            className="flex items-center justify-center bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700 dark:bg-red-600 dark:hover:bg-red-800 transition duration-300 w-40"
            aria-label="Delete selected items"
          >
            <FiTrash className="mr-2" />
            <span className="flex-1 text-center">Delete</span>
          </button>
        </div>
      </div>
      {/* {showContentModal && <MyModal onClose={() => setShowContentModal(false)} />} */}
      <TableS3H
        data={data}
        columns={column}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        onRequestDelete={requestRowDeletion}
        onEdit={handleEditClick}
      />

      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex justify-center items-center p-4">
          <div className="bg-white dark:bg-gray-800 w-full max-w-md mx-auto rounded shadow-lg overflow-hidden p-4">
            <p className="text-gray-800 dark:text-gray-200 text-center text-lg md:text-base">
              Are you sure you want to delete the selected items?
            </p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="bg-gray-300 dark:bg-gray-600 dark:text-gray-200 px-4 py-2 rounded mr-2 text-sm md:text-base hover:bg-gray-400 dark:hover:bg-gray-700 transition duration-300"
              >
                Cancel
              </button>
              <button
                onClick={() =>
                  rowToDelete !== null ? confirmDelete() : deleteSelectedRows()
                }
                className="bg-red-500 text-white px-4 py-2 rounded text-sm md:text-base hover:bg-red-600 transition duration-300"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {showContentModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex justify-center items-center p-4">
          <div className="bg-white dark:bg-gray-800 w-full max-w-lg mx-auto rounded shadow-lg overflow-hidden p-6 max-h-[90vh] overflow-y-auto">
            <h2 className="text-lg font-semibold mb-4">
              {modalMode === 'edit' ? 'Edit Data' : 'Add New Data'}
            </h2>
            <form onSubmit={handleAddNewSubmit}>
              {/* Category Dropdown */}
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Category
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.category}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      category: e.target.value,
                      reportBy: '',
                      assetCategory: '',
                      assetType: '',
                      lessor: '',
                      industryType: '',
                      buildingType: '',
                      fuel: '',
                      unit: '',
                      quantity: 0,
                      region: '',
                      allocation: 0,
                      totalAllocation: 0,
                      occupancyRate: 0,
                      electricity: 0,
                      refrigerant: '',
                      leakage: 0,
                      processActivity: 0,
                      assetEF: 0,
                      buildingEF: 0,
                    })
                  }
                  required
                >
                  <option value="">Select Type</option>
                  <option value="Upstream">Upstream</option>
                  <option value="Downstream">Downstream</option>
                </select>
              </div>

              {/* ReportBy Dropdown */}
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Report By
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.reportBy}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      reportBy: e.target.value,
                      assetCategory: '',
                      assetType: '',
                      lessor: '',
                      industryType: '',
                      buildingType: '',
                      fuel: '',
                      unit: '',
                      quantity: 0,
                      region: '',
                      allocation: 0,
                      totalAllocation: 0,
                      occupancyRate: 0,
                      electricity: 0,
                      refrigerant: '',
                      leakage: 0,
                      processActivity: 0,
                      assetEF: 0,
                      buildingEF: 0,
                    })
                  }
                  required
                >
                  <option value="">Select Type</option>
                  <option value="Asset-Specific Method">
                    Asset-Specific Method
                  </option>
                  <option value="Lessor-Specific Method">
                    Lessor-Specific Method
                  </option>
                  <option value="Average-Data Method">
                    Average-Data Method
                  </option>
                </select>
              </div>

              {/* Asset Categorty Select*/}
              {editableData.reportBy === 'Asset-Specific Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Asset Category
                  </label>
                  <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.assetCategory}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      assetCategory: e.target.value,
                      assetType: '',
                      fuel: '',
                      unit: '',
                      quantity: 0,
                      region: '',
                      allocation: 0,
                      totalAllocation: 0,
                      occupancyRate: 0,
                      electricity: 0,
                      refrigerant: '',
                      leakage: 0,
                      processActivity: 0,
                    })
                  }
                  required
                >
                  <option value="">Select Type</option>
                  <option value="Machinery/Vehicles">
                    Machinery/Vehicles
                  </option>
                  <option value="Building">
                    Building
                  </option>
                </select>
                </div>
              )}

              {/* Industry Type*/}
              {editableData.reportBy === 'Average-Data Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Industry
                  </label>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.industryType}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        industryType: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Building Type*/}
              {editableData.reportBy === 'Average-Data Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Building Type
                  </label>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.buildingType}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        buildingType: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Lessor*/}
              {editableData.reportBy === 'Lessor-Specific Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Lessor
                  </label>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.lessor}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        lessor: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Building EF Input */}
              {editableData.reportBy === 'Average-Data Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    <p>
                      Average Emission Factor for Building Type (kg CO2e / m
                      <sup>2</sup> / Year)
                    </p>
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.buildingEF}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        buildingEF: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Asset Type*/}
              {(editableData.reportBy === 'Average-Data Method' ||
                editableData.reportBy === 'Asset-Specific Method') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Asset Type
                  </label>
                  {editableData.reportBy === 'Average-Data Method' ? (
                    <input
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={editableData.assetType}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          assetType: e.target.value,
                        })
                      }
                    />
                  ) : (
                    <input
                      type="text"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={editableData.assetType}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          assetType: e.target.value,
                        })
                      }
                      required
                    />
                  )}
                </div>
              )}

              {/* Occupancy Rate Input*/}
              {editableData.reportBy && editableData.assetCategory === "Building" && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Occupancy Rate (%)
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.occupancyRate}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        occupancyRate: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Quantity Input */}
              {editableData.reportBy === "Average-Data Method" && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Number of Assets
                  </label>

                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.quantity}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        quantity: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Asset Type EF Input */}
              {editableData.reportBy === 'Average-Data Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    <p>
                      Average Emissions Per Asset Type (kg CO2e / Asset Type /
                      Year)
                    </p>
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.assetEF}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        assetEF: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Allocation Input */}
              {(editableData.reportBy && editableData.reportBy !== "Asset-Specific Method" || (editableData.reportBy === "Asset-Specific Method" && editableData.assetCategory === "Building")) && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    {allocationLabel()}
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.allocation}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        allocation: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Total Allocation Input */}
              {((editableData.reportBy === 'Asset-Specific Method' &&
                editableData.assetCategory === 'Building') ||
                editableData.reportBy === 'Lessor-Specific Method') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    {editableData.reportBy === 'Lessor-Specific Method' ? (
                      <p>
                        Total Allocation (m<sup>2</sup> or m<sup>3</sup>)
                      </p>
                    ) : (
                      <p>
                        Total Floor Space (m<sup>2</sup>)
                      </p>
                    )}
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.totalAllocation}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        totalAllocation: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {editableData.reportBy &&
                editableData.reportBy !== 'Average-Data Method' && (
                  <>
                    {/* Scope 1 */}
                    <div className="mt-2 mb-4 border border-black px-2 py-0.5 rounded-lg">
                      <details className="group">
                        <summary className="duration:300 p-2 text-sm font-bold flex justify-between items-center cursor-pointer list-none">
                          <span> Scope 1 Emission</span>
                          <span className="transition group-open:rotate-180">
                            <FaChevronDown size={10} />
                          </span>
                        </summary>

                        {/* Fuel Dropdown */}
                        <div className="px-2 mb-4">
                          <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                            Fuel Type
                          </label>
                          <select
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                            value={editableData.fuel}
                            onChange={(e) =>
                              setEditableData({
                                ...editableData,
                                fuel: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Fuel</option>
                            {fuel.map((fuel) => (
                              <option key={fuel} value={fuel}>
                                {fuel}
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* Unit Dropdown */}
                        <div className="px-2 mb-4">
                          <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                            Fuel Unit
                          </label>
                          {/* In hybrid method, options are needed for fuel*/}
                          <select
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                            value={editableData.unit}
                            onChange={(e) =>
                              setEditableData({
                                ...editableData,
                                unit: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Unit</option>
                            {/* To adapt to the availability of fuel's emission factor */}
                            {editableData.fuel &&
                              Object.keys(fuelEF[editableData.fuel]['CO2'])
                                .filter(
                                  (key) =>
                                    fuelEF[editableData.fuel]['CO2'][key] !== 0,
                                )
                                .map((unit) => (
                                  <option key={unit} value={unit}>
                                    {unit}
                                  </option>
                                ))}
                          </select>
                        </div>

                        {/* Quantity Input */}
                        <div className="px-2 mb-4">
                          <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                            Fuel Consumed
                          </label>
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                            value={editableData.quantity}
                            onChange={(e) =>
                              setEditableData({
                                ...editableData,
                                quantity: e.target.value,
                              })
                            }
                            required
                          />
                        </div>

                        {/* Refrigerant Type Dropdown */}
                        <div className="px-2 mb-4">
                          <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                            Refrigerant Type
                          </label>
                          <select
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                            value={editableData.refrigerant}
                            onChange={(e) =>
                              setEditableData({
                                ...editableData,
                                refrigerant: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Type</option>
                            {refrigerant.map((type) => (
                              <option key={type} value={type}>
                                {type}
                              </option>
                            ))}
                          </select>
                        </div>

                        {/* Refrigerant Leakage Input */}
                        <div className="px-2 mb-4">
                          <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                            Refrigerant Leakage (kg)
                          </label>
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                            value={editableData.leakage}
                            onChange={(e) =>
                              setEditableData({
                                ...editableData,
                                leakage: e.target.value,
                              })
                            }
                          />
                        </div>

                        {/* Process Activity Data Input */}
                        {(editableData.reportBy === 'Lessor-Specific Method' ||
                          editableData.assetCategory ===
                            'Machinery/Vehicles') && (
                          <div className="px-2 mb-4">
                            <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                              Process Activity Data (kg)
                            </label>
                            <input
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                              value={editableData.processActivity}
                              onChange={(e) =>
                                setEditableData({
                                  ...editableData,
                                  processActivity: e.target.value,
                                })
                              }
                            />
                          </div>
                        )}
                      </details>
                    </div>

                    {/* Scope 2 */}
                    <div className="mt-2 mb-4 border border-black px-2 py-0.5 rounded-lg">
                      <details className="group">
                        <summary className="duration:300 p-2 text-sm font-bold flex justify-between items-center cursor-pointer list-none">
                          <span> Scope 2 Emission</span>
                          <span className="transition group-open:rotate-180">
                            <FaChevronDown size={10} />
                          </span>
                        </summary>

                        {/* Region Dropdown (Site Specific))*/}
                        <div className="px-2 mb-4">
                          <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                            Region
                          </label>
                          <select
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                            value={editableData.region}
                            onChange={(e) =>
                              setEditableData({
                                ...editableData,
                                region: e.target.value,
                              })
                            }
                          >
                            <option value="">Select Type</option>
                            <option value="Peninsular">Peninsular</option>
                            <option value="Sabah">Sabah</option>
                            <option value="Sarawak">Sarawak</option>
                          </select>
                        </div>

                        {/* Electricity Consumption Input */}
                        <div className="px-2 mb-4">
                          <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                            Electricity Consumption (kWh)
                          </label>
                          <input
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                            value={editableData.electricity}
                            onChange={(e) =>
                              setEditableData({
                                ...editableData,
                                electricity: e.target.value,
                              })
                            }
                            required
                          />
                        </div>
                      </details>
                    </div>
                  </>
                )}

              {/* Description Input */}
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Description
                </label>
                <textarea
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.description}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      description: e.target.value,
                    })
                  }
                  required
                />
              </div>

              <div className="flex justify-end mt-4">
                <button
                  type="button"
                  onClick={() => setShowContentModal(false)}
                  className="bg-gray-300 dark:bg-gray-600 dark:text-gray-200 px-4 py-2 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

export default ReportLeasedAssets
