import { useState } from 'react';
import {
  FugitiveEmissionCard,
  MobileCombustionCard,
  StationaryCombustionCard,
} from './component/Dashboard/ScopeI';
import { PieChart, BarChart } from '@mui/x-charts';

import GuidelineModalToggleButton from './component/Dashboard/GuidelineModalToggleButton';
import GuidelineModal from './component/Dashboard/GuidelineModal';

const ScopeI = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleToggleModal = () => setModalOpen(!isModalOpen);

  return (
    <div className="flex flex-col bg-white dark:bg-stone-800 w-full h-full">
      <div className="flex flex-col lg:flex-row justify-around items-center pt-10 px-20 lg:pl-5 xl:pl-5">
      <PieChart
          colors={['#3266E3', '#4C9F54', '#8742E1']} 
          series={[
            {
              data: [
                { id: 0, value: 600, label: 'Stationary Combustion'},
                { id: 1, value: 300, label: 'Mobile Combustion'},
                { id: 2, value: 100, label: 'Fugitive Emission'},
              ],
            },
          ]}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'bottom', horizontal: 'middle' },
              itemMarkWidth: 30,
              itemMarkHeight: 10,
              markGap: 10,
              itemGap: 10,
              padding: 0,
            },
          }}
          margin={{ top: 20, bottom: 25, left: 5, right:10 }}
          width={400}
          height={300}
        />
        <BarChart
          colors={['#3266E3', '#4C9F54', '#8742E1']} 
          series={[
              {data: [600], label: 'Stationary Combustion' },
              {data: [300], label: 'Mobile Combustion' },
              {data: [100], label: 'Fugitive Emission' },
          ]}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'bottom', horizontal: 'middle' },
              itemMarkWidth: 30,
              itemMarkHeight: 10,
              markGap: 20,
              itemGap: 10,
              padding: 0,
            },
          }}
          margin={{ top: 20, bottom: 25, left: 5, right:10 }}
          width={400}
          height={300}
        />
      </div>
      <div className="flex flex-col lg:flex-row justify-around items-center pt-10 px-1 lg:pl-15 xl:pl-20">
        <StationaryCombustionCard />
        <MobileCombustionCard />
        <FugitiveEmissionCard />
      </div>
    </div>
  );
};

export default ScopeI;
