import React, { useEffect, useState } from 'react'
import { TableS3A } from './component/Table'
import { columnDefinitionPurchasedGoods } from './component/Table/column'
import { FiPlus, FiTrash } from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import axios from 'axios'
import * as types from './reportData.json'
import { FaChevronDown } from 'react-icons/fa'

const ReportPurchasedGoods = () => {
  const column = columnDefinitionPurchasedGoods
  const navigate = useNavigate()
  const location = useLocation()
  const axiosPrivate = useAxiosPrivate()
  const { bypassModal } = location.state === null ? false : location.state
  const { vehicleMode, vehicleType, fuelEF, fuel, transportMode } = types

  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [showContentModal, setShowContentModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [modalMode, setModalMode] = useState(null) // 'add' or 'edit'
  const [editableData, setEditableData] = useState({
    category: '',
    reportBy: '',
    purchasedGoodOrService: '',
    vehicleMode: '',
    vehicleType: '',
    region: '',
    fuel: '',
    unit: '',
    supplier: '',
    wasteType: '',
    materialType: '',
    currency: '',
    supplierWasteAmount: 0,
    quantity: 0,
    electricity: 0,
    materialAmount: 0,
    transportationDistance: 0,
    averageDataOrWasteEF: 0,
    supplierOrMaterialEF: 0,
    description: '',
  })
  const [selectedRows, setSelectedRows] = useState(new Set())
  const [rowToDelete, setRowToDelete] = useState(null)

  useEffect(() => {
    let isMounted = true
    const controller = new AbortController()

    const fetchData = async () => {
      setIsLoading(true)
      try {
        const response = await axiosPrivate.get(
          'purchasedGoods/read-S3A-records',
          {
            signal: controller.signal,
          },
        )

        if (isMounted) {
          // console.log(response.data);
          setData(response.data)
        }
      } catch (err) {
        if (axios.isCancel(err)) {
          // Log for debugging purposes
          // console.log('Request cancelled:', err.message);
        } else {
          console.error('Request failed:', err)
          // Only navigate if the error was not a cancellation
          // Check for a 403 status code specifically
          if (err.response && err.response.status === 403) {
            navigate('/sign-in', { state: { from: location }, replace: true })
          }
        }
      }

      setIsLoading(false)
    }

    fetchData()

    if (bypassModal === true) {
      handleAddNewClick()
    }

    return () => {
      isMounted = false
      controller.abort()
      // console.log('Cleanup: Cancelled any ongoing requests.');
    }
  }, [navigate, location, axiosPrivate])

  const handleAddNewClick = () => {
    setEditableData({
      category: '',
      reportBy: '',
      purchasedGoodOrService: '',
      vehicleMode: '',
      vehicleType: '',
      region: '',
      fuel: '',
      unit: '',
      supplier: '',
      wasteType: '',
      materialType: '',
      currency: '',
      supplierWasteAmount: 0,
      quantity: 0,
      electricity: 0,
      materialAmount: 0,
      transportationDistance: 0,
      averageDataOrWasteEF: 0,
      supplierOrMaterialEF: 0,
      description: '',
    })
    setModalMode('add')
    setShowContentModal(true)
  }

  const handleDeleteClick = () => {
    if (selectedRows.size > 0) {
      setShowDeleteModal(true)
    } else {
      alert('No rows selected') // Or show a more user-friendly message
    }
  }

  const requestRowDeletion = (rowCategory, rowId) => {
    setRowToDelete(rowId)
    setShowDeleteModal(true)
  }

  const confirmDelete = async () => {
    if (rowToDelete !== null) {
      let isMounted = true
      const controller = new AbortController()

      try {
        await axiosPrivate.delete(
          `/purchasedGoods/delete-S3A-record/${rowToDelete}`,
          {
            headers: { 'Content-Type': 'application/json' },
            signal: controller.signal,
          },
        )

        if (isMounted) {
          // Update the state to reflect the deletion
          setData((prevData) =>
            prevData.filter((row) => row.id !== rowToDelete),
          )
          setRowToDelete(null)
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error('Deletion failed:', err)
          if (err.response) {
            // Redirect on 403 Forbidden or show an error message
            if (err.response.status === 403) {
              navigate('/sign-in', {
                state: { from: location },
                replace: true,
              })
            } else {
              alert(
                `Error: ${err.response.data.message || 'An error occurred.'}`,
              )
            }
          }
        }
      } finally {
        if (isMounted) {
          setShowDeleteModal(false) // Close the modal upon completion or error
        }
      }

      // Cleanup function to prevent state updates if the component unmounts
      return () => {
        isMounted = false
        controller.abort()
      }
    } else {
      // If rowToDelete is null, just close the modal
      setShowDeleteModal(false)
    }
  }

  const deleteSelectedRows = async () => {
    // Check if any rows are selected for deletion
    if (selectedRows.size > 0) {
      let isMounted = true
      const controller = new AbortController()

      try {
        const recordIdsToDelete = Array.from(selectedRows)

        await axiosPrivate.delete('/purchasedGoods/delete-S3A-records', {
          data: { ids: recordIdsToDelete }, // Sending user IDs in the request body
          headers: { 'Content-Type': 'application/json' },
          signal: controller.signal,
        })

        if (isMounted) {
          // Filter out the deleted users from the current data
          setData((prevData) =>
            prevData.filter((row) => !selectedRows.has(row.id)),
          )
          setSelectedRows(new Set()) // Clear selected rows after successful deletion
        }
      } catch (err) {
        if (!axios.isCancel(err)) {
          console.error('Deletion failed:', err)
          if (err.response) {
            // Handle specific HTTP status codes here
            if (err.response.status === 403) {
              navigate('/sign-in', {
                state: { from: location },
                replace: true,
              })
            } else {
              alert(
                `Error: ${err.response.data.message || 'An error occurred.'}`,
              )
            }
          }
        }
      } finally {
        if (isMounted) {
          setShowDeleteModal(false) // Close the modal upon completion or error
        }
      }

      // Cleanup function to prevent state updates if the component unmounts
      return () => {
        isMounted = false
        controller.abort()
      }
    } else {
      // If no rows were selected, just close the modal
      setShowDeleteModal(false)
    }
  }

  // Function to handle edit requests
  const handleEditClick = (rowData) => {
    setEditableData(rowData)
    setModalMode('edit')
    setShowContentModal(true)
  }

  const handleAddNewSubmit = async (event) => {
    event.preventDefault()

    const endpoint =
      modalMode === 'edit'
        ? `/purchasedGoods/edit-S3A-record/${editableData.id}`
        : '/purchasedGoods/create-S3A-record'
    const method = modalMode === 'edit' ? 'patch' : 'post'

    // Prepare the data payload
    const payload = {
      scope: 3,
      category: `purchasedGoodsAndServices`,
      reportBy: editableData.reportBy,
      purchasedGoodOrService: editableData.purchasedGoodOrService,
      vehicleMode: editableData.vehicleMode,
      vehicleType: editableData.vehicleType,
      region: editableData.region,
      fuel: editableData.fuel,
      unit: editableData.unit,
      supplier: editableData.supplier,
      wasteType: editableData.wasteType,
      materialType: editableData.materialType,
      currency: editableData.currency,
      supplierWasteAmount: editableData.supplierWasteAmount,
      quantity: editableData.quantity,
      electricity: editableData.electricity,
      materialAmount: editableData.materialAmount,
      transportationDistance: editableData.transportationDistance,
      averageDataOrWasteEF: editableData.averageDataOrWasteEF,
      supplierOrMaterialEF: editableData.supplierOrMaterialEF,
      description: editableData.description,
    }

    try {
      const response = await axiosPrivate[method](endpoint, payload, {
        headers: { 'Content-Type': 'application/json' },
      })

      // Update the state based on the modal mode
      if (modalMode === 'edit') {
        setData((currentData) =>
          currentData.map((item) =>
            item.id === editableData.id ? { ...item, ...response.data } : item,
          ),
        )
      } else {
        setData((currentData) => [...currentData, response.data])
      }

      // Reset form and close modal
      setEditableData({
        category: '',
        reportBy: '',
        purchasedGoodOrService: '',
        vehicleMode: '',
        vehicleType: '',
        region: '',
        fuel: '',
        unit: '',
        supplier: '',
        wasteType: '',
        materialType: '',
        currency: '',
        supplierWasteAmount: 0,
        quantity: 0,
        electricity: 0,
        materialAmount: 0,
        transportationDistance: 0,
        averageDataOrWasteEF: 0,
        supplierOrMaterialEF: 0,
        description: '',
      })
      setShowContentModal(false)
    } catch (err) {
      if (!axios.isCancel(err)) {
        console.error('Submission failed:', err)
        const errorMsg = err.response?.data.message || 'An error occurred.'
        if (err.response?.status === 403) {
          navigate('/sign-in', { state: { from: location }, replace: true })
        } else {
          alert(`Error: ${errorMsg}`)
        }
      }
    }
  }

  function quantityLabel() {
    const labels = {
      'Supplier-Specific Method': 'Quantity (kg)',
      'Hybrid Method': 'Fuel Consumed',
      'Average-Data Method': 'Quantity',
      'Spend-Based Method': 'Value of Purchased Good or Service (RM)',
    }

    return labels[editableData.reportBy]
  }

  if (isLoading) {
    return <div>Loading...</div> // Or any loading spinner
  }

  return (
    <div className="flex flex-col bg-slate-400 dark:bg-stone-800 text-black dark:text-white p-4 md:p-10 w-full overflow-y-auto h-full">
      <div className="flex flex-col md:flex-row justify-between items-center mb-4">
        <h2 className="text-xl md:text-2xl font-bold mb-4 md:mb-0 dark:text-white">
          Purchased Goods and Services Reporting
        </h2>
        <div className="flex space-x-2">
          <button
            onClick={handleAddNewClick}
            className="flex items-center justify-center bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-700 dark:bg-blue-600 dark:hover:bg-blue-800 transition duration-300 w-40"
            aria-label="Add new item"
          >
            <FiPlus className="mr-2" />
            <span className="flex-1 text-center">Add</span>
          </button>
          <button
            onClick={handleDeleteClick}
            className="flex items-center justify-center bg-red-500 text-white py-2 px-4 rounded hover:bg-red-700 dark:bg-red-600 dark:hover:bg-red-800 transition duration-300 w-40"
            aria-label="Delete selected items"
          >
            <FiTrash className="mr-2" />
            <span className="flex-1 text-center">Delete</span>
          </button>
        </div>
      </div>
      {/* {showContentModal && <MyModal onClose={() => setShowContentModal(false)} />} */}
      <TableS3A
        data={data}
        columns={column}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
        onRequestDelete={requestRowDeletion}
        onEdit={handleEditClick}
      />

      {showDeleteModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex justify-center items-center p-4">
          <div className="bg-white dark:bg-gray-800 w-full max-w-md mx-auto rounded shadow-lg overflow-hidden p-4">
            <p className="text-gray-800 dark:text-gray-200 text-center text-lg md:text-base">
              Are you sure you want to delete the selected items?
            </p>
            <div className="flex justify-end mt-4">
              <button
                onClick={() => setShowDeleteModal(false)}
                className="bg-gray-300 dark:bg-gray-600 dark:text-gray-200 px-4 py-2 rounded mr-2 text-sm md:text-base hover:bg-gray-400 dark:hover:bg-gray-700 transition duration-300"
              >
                Cancel
              </button>
              <button
                onClick={() =>
                  rowToDelete !== null ? confirmDelete() : deleteSelectedRows()
                }
                className="bg-red-500 text-white px-4 py-2 rounded text-sm md:text-base hover:bg-red-600 transition duration-300"
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}

      {showContentModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-md flex justify-center items-center p-4">
          <div className="bg-white dark:bg-gray-800 w-full max-w-lg mx-auto rounded shadow-lg overflow-hidden p-6 max-h-[90vh] overflow-y-auto">
            <h2 className="text-lg font-semibold mb-4">
              {modalMode === 'edit' ? 'Edit Data' : 'Add New Data'}
            </h2>
            <form onSubmit={handleAddNewSubmit}>
              {/* ReportBy Dropdown */}
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Report By
                </label>
                <select
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.reportBy}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      reportBy: e.target.value,
                      purchasedGoodOrService: '',
                      vehicleMode: '',
                      vehicleType: '',
                      region: '',
                      fuel: '',
                      unit: '',
                      supplier: '',
                      wasteType: '',
                      materialType: '',
                      currency: '',
                      supplierWasteAmount: 0,
                      quantity: 0,
                      electricity: 0,
                      materialAmount: 0,
                      transportationDistance: 0,
                      averageDataOrWasteEF: 0,
                      supplierOrMaterialEF: 0,
                    })
                  }
                  required
                >
                  <option value="">Select Type</option>
                  <option value="Supplier-Specific Method">
                    Supplier-Specific Method
                  </option>
                  <option value="Hybrid Method">Hybrid Method</option>
                  <option value="Average-Data Method">
                    Average-Data Method
                  </option>
                  <option value="Spend-Based Method">Spend-Based Method</option>
                </select>
              </div>

              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Purchased Goods or Services
                </label>

                {editableData.reportBy === 'Spend-Based Method' ? (
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.purchasedGoodOrService}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        purchasedGoodOrService: e.target.value,
                      })
                    }
                    required
                  >
                    <option value="">Select Mode</option>
                    {transportMode.map((mode) => (
                      <option key={mode} value={mode}>
                        {mode}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.purchasedGoodOrService}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        purchasedGoodOrService: e.target.value,
                      })
                    }
                    required
                  />
                )}
              </div>

              {(editableData.reportBy === 'Supplier-Specific Method' ||
                editableData.reportBy === 'Hybrid Method') && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Supplier
                  </label>
                  <input
                    type="text"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.supplier}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        supplier: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Quantity Input */}
              {editableData.reportBy &&
                editableData.reportBy !== 'Hybrid Method' && (
                  <div className="mb-4">
                    <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                      {quantityLabel()}
                    </label>
                    <input
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                      value={editableData.quantity}
                      onChange={(e) =>
                        setEditableData({
                          ...editableData,
                          quantity: e.target.value,
                        })
                      }
                      required
                    />
                  </div>
                )}

              {/* Currency Dropdown */}
              {editableData.reportBy === 'Spend-Based Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Currency
                  </label>
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.currency}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        currency: e.target.value,
                      })
                    }
                    required
                  >
                    <option value="">Select Type</option>
                    <option value="2021 USD">2021 USD</option>
                  </select>
                </div>
              )}

              {/* Emission Factor Input for Supplier-Specific*/}
              {editableData.reportBy === 'Supplier-Specific Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    {editableData.reportBy === 'Supplier-Specific Method'
                      ? 'Supplier Emission Factor (kg CO2e / kg)'
                      : 'Cradle-to-Gate Emission Factor for Material (kg CO2e / kg or unit)'}
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.supplierOrMaterialEF}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        supplierOrMaterialEF: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {/* Unit Dropdown */}
              {editableData.reportBy === 'Average-Data Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Unit
                  </label>
                  {/* In average data method, options are needed for fuel*/}
                  <select
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.unit}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        unit: e.target.value,
                      })
                    }
                  >
                    <option value="">Select Unit</option>
                    <option value="Kilogram">Kilogram</option>
                    <option value="Hours">Hours Spent</option>
                  </select>
                </div>
              )}

              {/* Emission Factor Input for Average-Data*/}
              {editableData.reportBy === 'Average-Data Method' && (
                <div className="mb-4">
                  <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                    Emission Factor (kg CO2e / unit)
                  </label>
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                    value={editableData.averageDataOrWasteEF}
                    onChange={(e) =>
                      setEditableData({
                        ...editableData,
                        averageDataOrWasteEF: e.target.value,
                      })
                    }
                    required
                  />
                </div>
              )}

              {editableData.reportBy === 'Hybrid Method' && (
                <>
                  {/* Scope 1 */}
                  <div className="mt-2 mb-4 border border-black px-2 py-0.5 rounded-lg">
                    <details className="group">
                      <summary className="duration:300 p-2 text-sm font-bold flex justify-between items-center cursor-pointer list-none">
                        <span> Scope 1 Emission of Supplier </span>
                        <span className="transition group-open:rotate-180">
                          <FaChevronDown size={10} />
                        </span>
                      </summary>

                      {/* Fuel Dropdown */}
                      <div className="px-2 mb-4">
                        <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                          Fuel Type
                        </label>
                        <select
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          value={editableData.fuel}
                          onChange={(e) =>
                            setEditableData({
                              ...editableData,
                              fuel: e.target.value,
                            })
                          }
                          required
                        >
                          <option value="">Select Fuel</option>
                          {fuel.map((fuel) => (
                            <option key={fuel} value={fuel}>
                              {fuel}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* Unit Dropdown */}
                      <div className="px-2 mb-4">
                        <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                          Fuel Unit
                        </label>
                        {/* In hybrid method, options are needed for fuel*/}
                        <select
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          value={editableData.unit}
                          onChange={(e) =>
                            setEditableData({
                              ...editableData,
                              unit: e.target.value,
                            })
                          }
                        >
                          <option value="">Select Unit</option>
                          {/* To adapt to the availability of fuel's emission factor */}
                          {editableData.fuel &&
                            Object.keys(fuelEF[editableData.fuel]['CO2'])
                              .filter(
                                (key) =>
                                  fuelEF[editableData.fuel]['CO2'][key] !== 0,
                              )
                              .map((unit) => (
                                <option key={unit} value={unit}>
                                  {unit}
                                </option>
                              ))}
                        </select>
                      </div>

                      {/* Quantity Input */}
                      <div className="px-2 mb-4">
                        <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                          {quantityLabel()}
                        </label>
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          value={editableData.quantity}
                          onChange={(e) =>
                            setEditableData({
                              ...editableData,
                              quantity: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </details>
                  </div>

                  {/* Scope 2 */}
                  <div className="mt-2 mb-4 border border-black px-2 py-0.5 rounded-lg">
                    <details className="group">
                      <summary className="duration:300 p-2 text-sm font-bold flex justify-between items-center cursor-pointer list-none">
                        <span> Scope 2 Emission of Supplier </span>
                        <span className="transition group-open:rotate-180">
                          <FaChevronDown size={10} />
                        </span>
                      </summary>

                      {/* Region Dropdown (Site Specific))*/}
                      <div className="px-2 mb-4">
                        <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                          Region
                        </label>
                        <select
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          value={editableData.region}
                          onChange={(e) =>
                            setEditableData({
                              ...editableData,
                              region: e.target.value,
                            })
                          }
                          required
                        >
                          <option value="">Select Type</option>
                          <option value="Peninsular">Peninsular</option>
                          <option value="Sabah">Sabah</option>
                          <option value="Sarawak">Sarawak</option>
                        </select>
                      </div>

                      {/* Electricity Consumption Input */}
                      <div className="px-2 mb-4">
                        <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                          Electricity Consumption (kWh)
                        </label>
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          value={editableData.electricity}
                          onChange={(e) =>
                            setEditableData({
                              ...editableData,
                              electricity: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </details>
                  </div>

                  {/* Waste Output and Material Input */}
                  <div className="mt-2 mb-4 border border-black px-2 py-0.5 rounded-lg">
                    <details className="group">
                      <summary className="duration:300 p-2 text-sm font-bold flex justify-between items-center cursor-pointer list-none">
                        <span> Waste Output and Material Input </span>
                        <span className="transition group-open:rotate-180">
                          <FaChevronDown size={10} />
                        </span>
                      </summary>

                      {/* Waste Output by Supplier */}
                      <div className="px-2 mb-4">
                        <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                          Waste Outputs by Supplier (kg)
                        </label>
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          value={editableData.supplierWasteAmount}
                          onChange={(e) =>
                            setEditableData({
                              ...editableData,
                              supplierWasteAmount: e.target.value,
                            })
                          }
                          required
                        />
                      </div>

                      {/* Waste Treatment Type*/}
                      <div className="px-2 mb-4">
                        <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                          Waste Treatment Type
                        </label>
                        {/* <select
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          value={editableData.wasteType}
                          onChange={(e) =>
                            setEditableData({
                              ...editableData,
                              wasteType: e.target.value,
                            })
                          }
                          required
                        >
                          <option value="">Select Type</option>
                          <option value="Landfill">Landfill</option>
                        </select> */}
                        <input
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          value={editableData.wasteType}
                          onChange={(e) =>
                            setEditableData({
                              ...editableData,
                              wasteType: e.target.value,
                            })
                          }
                          required
                        />
                      </div>

                      {/* Emission Factor Input for Average-Data*/}
                      <div className="px-2 mb-4">
                        <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                          Waste Activity Emission Factor (kg CO2e / kg)
                        </label>
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          value={editableData.averageDataOrWasteEF}
                          onChange={(e) =>
                            setEditableData({
                              ...editableData,
                              averageDataOrWasteEF: e.target.value,
                            })
                          }
                          required
                        />
                      </div>

                      {/* Waste Treatment Type*/}
                      <div className="px-2 mb-4">
                        <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                          Material Type
                        </label>
                        {/* <select
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          value={editableData.materialType}
                          onChange={(e) =>
                            setEditableData({
                              ...editableData,
                              materialType: e.target.value,
                            })
                          }
                          required
                        >
                          <option value="">Select Type</option>
                          <option value="Cotton">Cotton</option>
                          <option value="Polymer">Polymer</option>
                          <option value="Chemical A">Chemical A</option>
                          <option value="Chemical B">Chemical B</option>
                        </select> */}
                        <input
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          value={editableData.materialType}
                          onChange={(e) =>
                            setEditableData({
                              ...editableData,
                              materialType: e.target.value,
                            })
                          }
                          required
                        />
                      </div>

                      {/* Waste Output by Supplier */}
                      <div className="px-2 mb-4">
                        <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                          Material Input Used by Supplier (kg or unit)
                        </label>
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          value={editableData.materialAmount}
                          onChange={(e) =>
                            setEditableData({
                              ...editableData,
                              materialAmount: e.target.value,
                            })
                          }
                          required
                        />
                      </div>

                      {/* Emission Factor Input for Average-Data*/}
                      <div className="px-2 mb-4">
                        <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                          Cradle-to-Gate Emission Factor for Material (kg CO2e /
                          kg or unit)
                        </label>
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          value={editableData.supplierOrMaterialEF}
                          onChange={(e) =>
                            setEditableData({
                              ...editableData,
                              supplierOrMaterialEF: e.target.value,
                            })
                          }
                          required
                        />
                      </div>

                      {/* Vehicle Mode Dropdown*/}
                      <div className="px-2 mb-4">
                        <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                          Vehicle Mode
                        </label>
                        <select
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          value={editableData.vehicleMode}
                          onChange={(e) =>
                            setEditableData({
                              ...editableData,
                              vehicleMode: e.target.value,
                            })
                          }
                          required
                        >
                          <option value="">Select Mode</option>
                          {Object.keys(vehicleMode).map((vehicle) => (
                            <option key={vehicle} value={vehicle}>
                              {vehicle}
                            </option>
                          ))}
                        </select>
                      </div>

                      {/* Vehicle Type Dropdown*/}
                      <div className="px-2 mb-4">
                        <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                          Vehicle Type
                        </label>
                        <select
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          value={editableData.vehicleType}
                          onChange={(e) =>
                            setEditableData({
                              ...editableData,
                              vehicleType: e.target.value,
                            })
                          }
                          required
                        >
                          <option value="">Select Type</option>
                          {editableData.vehicleMode &&
                            vehicleMode[editableData.vehicleMode].map(
                              (vehicle) => (
                                <option key={vehicle} value={vehicle}>
                                  {vehicle}
                                </option>
                              ),
                            )}
                        </select>
                      </div>

                      {/* Emission Factor Input for Average-Data*/}
                      <div className="px-2 mb-4">
                        <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                          Distance of Material Input for Transportation to
                          Supplier (km)
                        </label>
                        <input
                          type="number"
                          onWheel={(e) => e.target.blur()}
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                          value={editableData.transportationDistance}
                          onChange={(e) =>
                            setEditableData({
                              ...editableData,
                              transportationDistance: e.target.value,
                            })
                          }
                          required
                        />
                      </div>
                    </details>
                  </div>
                </>
              )}

              {/* Description Input */}
              <div className="mb-4">
                <label className="block mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
                  Description
                </label>
                <textarea
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white"
                  value={editableData.description}
                  onChange={(e) =>
                    setEditableData({
                      ...editableData,
                      description: e.target.value,
                    })
                  }
                  required
                />
              </div>

              <div className="flex justify-end mt-4">
                <button
                  type="button"
                  onClick={() => setShowContentModal(false)}
                  className="bg-gray-300 dark:bg-gray-600 dark:text-gray-200 px-4 py-2 rounded mr-2"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="bg-blue-500 text-white px-4 py-2 rounded"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  )
}

export default ReportPurchasedGoods
