import { DateTime } from 'luxon';

export const columnDefinition = [
  // {
  //   header: 'ID',
  //   accessorKey: 'id',
  //   footer: 'ID',
  // },
  {
    header: 'Vehicle Type',
    accessorKey: 'vehicleType',
    footer: 'Vehicle Type',
  },
  // {
  //   header: 'Source',
  //   accessorKey: 'source',
  //   footer: 'Source',
  // },
  {
    header: 'Reporting Basis',
    accessorKey: 'reportingBasis',
    footer: 'Reporting Basis',
  },
  {
    header: 'Quantity',
    accessorKey: 'quantity',
    footer: 'Quantity',
  },  
  {
    header: 'Unit',
    accessorKey: 'unit',
    footer: 'Unit',
  },
  // {
  //   header: 'Created By',
  //   accessorKey: 'createdBy',
  //   footer: 'Created By',
  // },
  {
    header: 'Date of Record',
    accessorKey: 'createdAt',
    footer: 'Date of Record',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
  // {
  //   header: 'Actions',
  //   id: 'actions',
  //   // cell: ({ row }) => <button onClick={() => handleEdit(row.original)}>Edit</button>,
  //   cell: ({ row }) => <button onClick={() => {}}>Edit</button>,
  // },
];

export const columnDefinitionMobileCombustion = [

  {
    header: 'Vehicle Type',
    accessorKey: 'vehicleType',
    footer: 'Vehicle Type',
  },
  {
    header: 'Report By',
    accessorKey: 'reportBy',
    footer: 'Report By',
  },
  {
    header: 'Quantity',
    accessorKey: 'quantity',
    footer: 'Quantity',
  },  
  {
    header: 'Emission (Tonnes)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes)',
  },
  {
    header: 'Date of Record',
    accessorKey: 'createdAt',
    footer: 'Date of Record',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
];

export const columnDefinitionPurchasedGoods = [
  {
    header: 'Report By',
    accessorKey: 'reportBy',
    footer: 'Report By',
  },
  {
    header: 'Purchased Good or Service',
    accessorKey: 'purchasedGoodOrService',
    footer: 'Purchased Good or Service',
  },  
  {
    header: 'Supplier',
    accessorKey: 'supplier',
    footer: 'Supplier',
  },  
  {
    header: 'Quantity',
    accessorKey: 'quantity',
    footer: 'Quantity',
  },  
  {
    header: 'Emission (Tonnes)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes)',
  },
  {
    header: 'Date of Record',
    accessorKey: 'createdAt',
    footer: 'Date of Record',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
];
export const columnDefinitionLeasedAssets = [
  {
    header: 'Report By',
    accessorKey: 'reportBy',
    footer: 'Report By',
  },
  {
    header: 'Category',
    accessorKey: 'category',
    footer: 'Category',
  },  
  {
    header: 'Asset Type',
    accessorKey: 'assetType',
    footer: 'Asset Type',
  },  
  {
    header: 'Emission (Tonnes)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes)',
  },
  {
    header: 'Date of Record',
    accessorKey: 'createdAt',
    footer: 'Date of Record',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
];

export const columnDefinitionTransportationDistribution = [
  {
    header: 'Category',
    accessorKey: `category`,
    footer: 'Category',
  },
  {
    header: 'Emission Type',
    accessorKey: `emissionType`,
    footer: 'Emission Typye',
  },
  {
    header: 'Report By',
    accessorKey: 'reportBy',
    footer: 'Report By',
  },
  {
    header: 'Leakage',
    accessorKey: 'leakage',
    footer: 'Leakage',
  },
  {
    header: 'Quantity',
    accessorKey: 'quantity',
    footer: 'Quantity',
  }, 
  {
    header: 'Emission (Tonnes)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes)',
  },
  {
    header: 'Description',
    accessorKey: 'description',
    footer: 'Description',
  },
  {
    header: 'Date of Record',
    accessorKey: 'createdAt',
    footer: 'Date of Record',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
];

export const columnDefinitionBusinessTravel = [
  {
    header: 'Report By',
    accessorKey: 'reportBy',
    footer: 'Report By',
  },
  {
    header: 'Vehicle Type',
    accessorKey: 'vehicleType',
    footer: 'Vehicle Type',
  },
  {
    header: 'Quantity',
    accessorKey: 'quantity',
    footer: 'Quantity',
  },  
  {
    header: 'Unit',
    accessorKey: 'unit',
    footer: 'Unit',
  },
  {
    header: 'Emission (Tonnes)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes)',
  },
  {
    header: 'Date of Record',
    accessorKey: 'createdAt',
    footer: 'Date of Record',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
];

export const columnDefinitionEmployeeCommuting = [
  {
    header: 'Mode Number',
    accessorKey: 'modeNumber',
    footer: 'Mode Number',
  },
  {
    header: 'Transportation Mode',
    accessorKey: 'transportationMode',
    footer: 'Transportation Mode',
  },
  {
    header: 'Quantity',
    accessorKey: 'quantity',
    footer: 'Quantity',
  },  
  {
    header: 'Unit',
    accessorKey: 'unit',
    footer: 'Unit',
  },
  {
    header: 'Emission (Tonnes)',
    accessorKey: 'emission',
    footer: 'Emission (Tonnes)',
  },
  {
    header: 'Date of Record',
    accessorKey: 'createdAt',
    footer: 'Date of Record',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
];

export const columnDefinitionUsers = [
  {
    header: 'Username',
    accessorKey: 'username',
    footer: 'Username',
  },
  {
    header: 'Full Name',
    accessorKey: 'name',
    footer: 'Full Name',
  },
  {
    header: 'Email',
    accessorKey: 'email',
    footer: 'Email',
  },
  {
    header: 'Gender',
    accessorKey: 'gender',
    footer: 'Gender',
  },
  {
    header: 'User Role',
    accessorKey: 'userRole',
    footer: 'User Role',
  },
];

export const columnDefinitionEnergyPurchase = [
  // {
  //   header: 'ID',
  //   accessorKey: 'id',
  //   footer: 'ID',
  // },
  {
    header: 'Energy Source',
    accessorKey: 'eSource',
    footer: 'Energy Source',
  },
  {
    header: 'Emission Factor (MgCO2e/MWh)',
    accessorKey: 'eFactor',
    footer: 'Emission Factor',
  },
  {
    header: 'Comsumption (MWh)',
    accessorKey: 'consumption',
    footer: 'Consumption',
  },
  {
    header: 'Location',
    accessorKey: 'location',
    footer: 'Location',
  },
  {
    header: 'Emission (MgCO2e)',
    accessorKey: 'emission',
    footer: 'Emission',
  },
  // {
  //   header: 'Created By',
  //   accessorKey: 'createdBy',
  //   footer: 'Created By',
  // },
  {
    header: 'Date of Record',
    accessorKey: 'createdAt',
    footer: 'Date of Record',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
  // {
  //   header: 'Actions',
  //   id: 'actions',
  //   // cell: ({ row }) => <button onClick={() => handleEdit(row.original)}>Edit</button>,
  //   cell: ({ row }) => <button onClick={() => {}}>Edit</button>,
  // },
];

export const columnDefinitionElectricVehicle = [
  // {
  //   header: 'ID',
  //   accessorKey: 'id',
  //   footer: 'ID',
  // },
  // {
  //   header: 'Provider',
  //   accessorKey: 'provider',
  //   footer: 'Provider',
  // },
  // {
  //   header: 'Charging Hub',
  //   accessorKey: 'chargingHub',
  //   footer: 'Charging Hub',
  // },
  {
    header: 'Rate (RM/kWh)',
    accessorKey: 'rate',
    footer: 'rate',
  },
  {
    header: 'Unit',
    accessorKey: 'unit',
    footer: 'Unit',
  },
  {
    header: 'Quantity',
    accessorKey: 'quantity',
    footer: 'Quantity',
  },
  {
    header: 'Location',
    accessorKey: 'location',
    footer: 'Location',
  },
  {
    header: 'Emission (kgCO2e)',
    accessorKey: 'emission',
    footer: 'Emission',
  },
  // {
  //   header: 'Created By',
  //   accessorKey: 'createdBy',
  //   footer: 'Created By',
  // },
  {
    header: 'Date of Record',
    accessorKey: 'createdAt',
    footer: 'Date of Record',
    cell: (info) =>
      DateTime.fromISO(info.getValue()).toLocaleString(DateTime.DATE_MED),
  },
  // {
  //   header: 'Actions',
  //   id: 'actions',
  //   // cell: ({ row }) => <button onClick={() => handleEdit(row.original)}>Edit</button>,
  //   cell: ({ row }) => <button onClick={() => {}}>Edit</button>,
  // },
];