import React from 'react';
import { MdCardTravel } from 'react-icons/md';
import { BiBuildingHouse } from 'react-icons/bi';
import { ReportingCard } from './ReportingCard';

// Business Travel Card
export const BusinessTravelCard = () => (
  <ReportingCard
    icon={<MdCardTravel />}
    title="Business Travel"
    description="Emissions from employee travel for business purposes, including various transportation modes that are not owned or controlled by the organization."
    link="/scope-iii/business-travel"
    bgColor="bg-blue-600 dark:bg-blue-800"
    textColor="text-white"
  />
);

// Employee Commuting Card
export const EmployeeCommutingCard = () => (
  <ReportingCard
    icon={<BiBuildingHouse />}
    title="Employee Commuting"
    description="Emissions from the employee transportation between worksites and home, including various transportation modes that are not owned or operated by the organization."
    link="/scope-iii/employee-commuting"
    bgColor="bg-pink-600 dark:bg-pink-800"
    textColor="text-white"
  />
);

// Transportation and Distribution
export const TransportationDistributionCard = () => (
  <ReportingCard
    icon={<BiBuildingHouse />}
    title="Transportation and Distribution"
    description="Description"
    link="/scope-iii/transportation-distribution"
    bgColor="bg-teal-600 dark:bg-teal-800"
    textColor="text-white"
  />
);

// Purchased Goods and Services
export const PurchasedGoodsAndServicesCard = () => (
  <ReportingCard
    icon={<BiBuildingHouse />}
    title="Purchased Goods and Services"
    description="Description"
    link="/scope-iii/purchased-goods-services"
    bgColor="bg-violet-600 dark:bg-violet-800"
    textColor="text-white"
  />
);

// Capital Goods
export const CapitalGoodsCard = () => (
  <ReportingCard
    icon={<BiBuildingHouse />}
    title="Capital Goods"
    description="Description"
    link="/scope-iii/capital-goods"
    bgColor="bg-sky-600 dark:bg-sky-800"
    textColor="text-white"
  />
);

// Leased Assets
export const LeasedAssetsCard = () => (
  <ReportingCard
    icon={<BiBuildingHouse />}
    title="Leased Assets"
    description="Description"
    link="/scope-iii/leased-assets"
    bgColor="bg-green-600 dark:bg-green-800"
    textColor="text-white"
  />
);